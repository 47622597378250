import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";

const yaSlotConfig: IYaSlotConfig = {
  id: "adfox_169286164392733385",
  p1: "cwazu",
  p2: "htnq",
  pfc: "fgigp",
};

export default yaSlotConfig;
